import axios from "axios";

export default class Api {
	constructor() {}

	token = localStorage.getItem("token");
	urlApi = process.env.VUE_APP_ROOT_API + "api/";

	post(url, dados) {
		return axios
			.post(
				this.urlApi + url, {
					...dados
				}, {
					headers: {
						Authorization: localStorage.getItem("token")
					}
				}
			)
			.then(response => {
				return response.data;
			})
			.catch(error => {
				return error.response;
				// this.redirectError(error.response.status);
			});
	}

	postUrlCompleta(url, dados) {
		return axios
			.post(url,
				{ ...dados },
				
			)
			.then(response => {
				return response.data;
			})
			.catch(error => {
				return error.response;
				// this.redirectError(error.response.status);
			});	
    }

	put(url, dados) {
		return axios
			.put(
				this.urlApi + url, {
					...dados
				}, {
					headers: {
						Authorization: localStorage.getItem("token")
					}
				}
			)
			.then(response => {
				return response.data;
			})
			.catch(error => {
				return error.response;
				// this.redirectError(error.response.status);
			});
	}

	get(url) {
		return axios
			.get(this.urlApi + url, {
				headers: {
					Authorization: localStorage.getItem("token")
				}
			})
			.then(response => {
				return response.data;
			});
	}

	getUrlCompleta(url) {
		return axios.get(url).then(response => {
			return response.data;
		});
	}

	delete(url) {
		return axios
			.delete(this.urlApi + url, {
				headers: {
					Authorization: localStorage.getItem("token")
				}
			})
			.then(response => {
				return response.data;
			});
	}

	file(url, obForm) {
		let formData = new FormData();

		Object.keys(obForm).forEach(key => {
			formData.append(key, (!obForm[key] && obForm[key] != 0) ? "" : obForm[key])
		})

		return axios
			.post(this.urlApi + url, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: localStorage.getItem("token")
				}
			})
			.then(response => {
				return response.data;
			});
	}

	/*
		(url, dados) {
		return axios.post(
			process.env.VUE_APP_ROOT_API + url,
			dados, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Api-Token': localStorage.getItem("token")
				}
			}
		).then(
			((response) => {
				return response.data
			})
		)
	}
	*/

	redirectError(statusCode) {
		switch (parseInt(statusCode)) {
			case 401:
				window.location = "/erro/401";
				break;
			case 404:
			default:
				window.location = "/erro/404";
				break;
		}
	}

	requisicao = axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (
				window.location.pathname != "/" &&
				window.location.pathname != "/erro/404" &&
				window.location.pathname.indexOf("/validar") === -1
			) {
				switch (parseInt(error.response.status)) {
					case 401:
						if (error.response.data.retorno == "Login inválido") {
							localStorage.removeItem("token");
							localStorage.removeItem("nome_usuario");
							localStorage.removeItem("HASH_USER");
							window.location = "/";
						} else if (
							error.response.data.retorno == "Login expirado"
						) {
							if (localStorage.getItem("HASH_USER")) {
								new Api()
									.getUrlCompleta(
										"http://www.econeteditora.com.br/acesso_spt_login.php?id=" +
										localStorage.getItem("HASH_USER")
									)
									.then(response => {
										if (response.blLogado) {
											//refresh token
											new Api()
												.get(
													"validarLogin/" +
													localStorage.getItem("HASH_USER")
												)
												.then(response => {
													let token = response.retorno.token;
													let nome_usuario = response.retorno.nome;
													localStorage.setItem("token",token);
													localStorage.setItem("nome_usuario",nome_usuario);
													error.config.headers["Api-Token"] = token;

													window.location.reload(true);

													return axios.request(error.config);
												})
												.catch(error => {
													console.log(
														"deu erro: ",
														error
													);
													//window.location = "/";
												});
										} else {
											localStorage.removeItem("token");
											localStorage.removeItem("nome_usuario");
											localStorage.removeItem(
												"HASH_USER"
											);
											window.location = "/";
										}
									});
							} else {
								localStorage.removeItem("token");
								localStorage.removeItem("nome_usuario");
								localStorage.removeItem("HASH_USER");
								//window.location = "/";

								console.log("não tem o hash");
							}
						}
						break;
					case 404:
						Object.noty.error(
							"Erro ao acessar a API, entre em contato com o suporte.", {
								timeout: 10000
							}
						);
						break;
					case 408:
						Object.noty.error(
							"Erro ao acessar a API, entre em contato com o suporte.", {
								timeout: 10000
							}
						);
						break;
					case 500:
						Object.noty.error(
							"Erro ao acessar a API, entre em contato com o suporte.", {
								timeout: 10000
							}
						);
						break;
					default:
						return Promise.reject(error);
				}
			}
		}
	);
}