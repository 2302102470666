import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
		path: "/admin",
		name: "admin",
		component: () => import("../views/admin/Admin.vue"),
		children: [{
					path: "carrousel",
					name: "admin-carrousel",
					component: () =>
						import(
							"../views/admin/Carrousel/Carrousel.vue"
						)
				},{
				path: "home",
				name: "admin-home",
				component: () =>
					import(
						"../views/admin/Home/Home.vue"
					)
			},
			{
				path: "assuntos",
				name: "admin-assunto",
				component: () =>
					import(
						"../views/admin/Assunto/ListaAssunto/ListaAssunto.vue"
					)
			},
			{
				path: "assuntos/cadastro/:id?",
				name: "admin-assunto-cadastro",
				component: () =>
					import(
						"../views/admin/Assunto/FormularioAssunto/FormularioAssunto.vue"
					)
			},
			{
				path: "temas",
				name: "admin-tema",
				component: () =>
					import(
						"../views/admin/Tema/ListaTema/ListaTema.vue"
					)
			},
			{
				path: "temas/cadastro/:id?",
				name: "admin-tema-cadastro",
				component: () =>
					import(
						"../views/admin/Tema/FormularioTema/FormularioTema.vue"
					)
			},
			{
				path: "conteudos",
				name: "admin-conteudo",
				component: () =>
					import(
						"../views/admin/Conteudo/ListaConteudo/ListaConteudo.vue"
					)
			},
			{
				path: "conteudos/cadastro/:id?",
				name: "admin-conteudo-cadastro",
				component: () =>
					import(
						"../views/admin/Conteudo/FormularioConteudo/FormularioConteudo.vue"
					)
			},
			{
				path: "noticias",
				name: "admin-noticia",
				component: () =>
					import(
						"../views/admin/Noticia/ListaNoticia/ListaNoticia.vue"
					)
			},
			{
				path: "noticias/cadastro/:id?",
				name: "admin-noticia-cadastro",
				component: () =>
					import(
						"../views/admin/Noticia/FormularioNoticia/FormularioNoticia.vue"
					)
			},
			{
				path: "eventos",
				name: "admin-evento",
				component: () =>
					import("../views/admin/Evento/ListaEvento/ListaEvento.vue")
			},
			{
				path: "eventos/cadastro/:id?",
				name: "admin-evento-cadastro",
				component: () =>
					import(
						"../views/admin/Evento/FormularioEvento/FormularioEvento.vue"
					)
			},
			{
				path: "checklist",
				name: "admin-checklist",
				component: () =>
					import("../views/admin/Checklist/ListaChecklist/ListaChecklist.vue")
			},
			{
				path: "checklist/cadastro/:id?",
				name: "admin-checklist-cadastro",
				component: () =>
					import(
						"../views/admin/Checklist/FormularioChecklist/FormularioChecklist.vue"
					)
			},
			{
				path: 'videos',
				name: "admin-videos",
				component: () => import("../views/admin/Video/ListaVideo/ListaVideo.vue")
			},
			{
				path: "videos/cadastro/:id?",
				name: "admin-videos-cadastro",
				component: () => import("../views/admin/Video/FormularioVideo/FormularioVideo.vue")
			},
			{
				path: 'categorias',
				name: "admin-categorias",
				component: () => import("../views/admin/Categoria/ListaCategoria/ListaCategoria.vue")
			},
			{
				path: "categorias/cadastro/:id?",
				name: "admin-categorias-cadastro",
				component: () => import("../views/admin/Categoria/FormularioCategoria/FormularioCategoria.vue")
			},
			{
				path: 'banners',
				name: "admin-banners",
				component: () => import("../views/admin/Banner/ListaBanner/ListaBanner.vue")
			},
			{
				path: "banners/cadastro/:id?",
				name: "admin-banners-cadastro",
				component: () => import("../views/admin/Banner/FormularioBanner/FormularioBanner.vue")
			},

		]
	},
	{
		path: "/",
		name: "cliente",
		component: () => import("../views/cliente/cliente.vue"),
		children: [{
				path: "/",
				name: "Home",
				component: () => import("../views/cliente/Home/Home.vue")
			},
			{
				path: "/validar-login/:id/:url?",
				name: "validar-login",
				component: () => import("../views/login/Login.vue")
			},
			{
				path: "perguntas-e-respostas/:parametro?",
				name: "perguntas-e-respostas",
				component: () =>
					import(
						"../views/cliente/PerguntasRespostas/PerguntasRespostas.vue"
					)
			},
			{
				path: "noticias",
				name: "noticias",
				component: () =>
					import("../views/cliente/Noticias/Noticias/Noticias.vue")
			},
			{
				path: "legislacao/:area",
				name: "legislacao",
				component: () =>
					import("../views/cliente/Legislacoes/Legislacoes.vue")
			},
			{
				path: "passo-a-passo",
				name: "passo-a-passo",
				component: () =>
					import("../views/cliente/Infografo/Infografo.vue")
			},
			{
				path: "checkList",
				name: "checkList",
				component: () =>
					import("../views/cliente/CheckList/CheckList.vue")
			},
			{
				path: "videos",
				name: "videos",
				component: () => import("../views/cliente/Videos/Videos.vue")
			},
			{
				path: "noticia/:slug/:rascunho?",
				name: "noticia",
				component: () =>
					import("../views/cliente/Noticias/Noticia/Noticia.vue")
			},
			{
				path: "visualizar/:id",
				name: "Vizualizar",
				component: () =>
					import("../views/cliente/Conteudo/Conteudo.vue")
			},
			{
				path: "pesquisa",
				name: "pesquisa",
				component: () =>
					import("../views/cliente/Pesquisa/Pesquisa.vue")
			},
			{
				path: "/:slug/:idConteudo?",
				name: "Tema",
				component: () => import("../views/cliente/Tema/Tema.vue")
			}
		]
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		};
	},
	routes
});

export default router;