import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/css/style.scss";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import axios from "axios";
import VueAxios from "vue-axios";
import {
	Utils
} from "./services/Utils.js";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import VueGtag from "vue-gtag";
import VueScrollactive from "vue-scrollactive";
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueLodash from "vue-lodash";



Vue.config.productionTip = false;
Vue.prototype.$utils = Utils;
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueScrollactive);
Vue.use(Datetime)
Vue.use(VueLodash, {
	name: "lodash"
});

Vue.use(VueGtag, {
	config: { id: "G-4QN8VQVJMP" }
}, router);

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount("#app");